const API =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_USE_DEV_PROXY
      ? 'http://localhost:3000'
      : `${process.env.BACKEND_SCHEME || 'http'}://${process.env.BACKEND_HOST || 'localhost'}:${process.env.BACKEND_PORT || 3001}`
    : `${process.env.BACKEND_SCHEME || 'https'}://${process.env.BACKEND_HOST || 'iocharts.io'}:${process.env.BACKEND_PORT || 443}`;

function isServer() {
  return !(typeof window != 'undefined');
}

export function setPrefix(url: string) {
  const prefix = isServer() ? API : '';
  return `${prefix}${url}`;
}

export class FetchError extends Error {
  constructor(
    message,
    public response: Response,
  ) {
    super(message);
  }
}

export const ApiRoutes = {
  Company: {
    getAll: () => setPrefix('/api/data/company'),
    getLinksByTicker: (ticker: string) => setPrefix(`/api/data/company/${ticker}/links`),
    getHomeStocksData: (tickers: string[]) => setPrefix(`/api/data/company/home?tickers=${tickers.join(',')}`),
    rss: (url: string) => setPrefix('/api/rss/?url=' + encodeURIComponent(url)),
    rssWithPagination: (url: string, skip?, limit?) =>
      setPrefix(
        '/api/rss/?url=' + encodeURIComponent(url) + (skip ? '&skip=' + skip : '') + (limit ? '&limit=' + limit : ''),
      ),
    rssHome: (skip: number, limit: number, locale: string) =>
      setPrefix(`/api/rss/home?skip=${skip}&limit=${limit}&locale=${locale}`),
  },
  Crypto: {
    priceByTicker: (tickers: string, from: string, to: string) =>
      setPrefix(`/api/data/chart/crypto/price?tickers=${tickers}&from=${from}&to=${to}`),
    downside: (tickers: string, from: string, to: string) =>
      setPrefix(`/api/data/chart/crypto/downside-potential?tickers=${tickers}&from=${from}&to=${to}`),
    priceByTickerPost: () => setPrefix(`/api/data/chart/crypto/price`),
  },
  Stock: {
    dpsByTicker: (tickers: string, from: string, to: string) =>
      setPrefix(`/api/data/dividends/dps?tickers=${tickers}&from=${from}&to=${to}`),
    dividendYieldByTicker: (tickers: string, from: string, to: string) =>
      setPrefix(`/api/data/chart/dividend-yield-by-time?tickers=${tickers}&from=${from}&to=${to}`),
    indicatorsByTicker: (tickers: string[], fields: string[]) =>
      setPrefix(
        `/api/data/indicator/odata?$filter=${tickers.map((ticker) => `ticker eq '${ticker}'`).join(' or ')}&$select=${fields.join(',')}`,
      ),
    priceByTickerCrypto: (tickers: string, from: string, to: string) =>
      setPrefix(`/api/data/chart/crypto/price?tickers=${tickers}&from=${from}&to=${to}`),

    priceByTicker: (tickers: string, from: string, to: string) =>
      setPrefix(`/api/data/chart/price?tickers=${tickers}&from=${from}&to=${to}`),
    priceByTickerV2: (tickers: string, from: string, to: string) =>
      setPrefix(`/api/data/v2/chart/price?tickers=${tickers}&from=${from}&to=${to}`),
    priceByTickerPost: () => setPrefix(`/api/data/chart/price`),
    priceByTickerV2Post: () => setPrefix(`/api/data/v2/chart/price`),

    downsideByTicker: (tickers: string, from: string, to: string) =>
      setPrefix(`/api/data/chart/downside-potential?tickers=${tickers}&from=${from}&to=${to}`),
    percentileByTicker: (ticker: string) => setPrefix(`/api/data/indicator/div-perc?ticker=${ticker}`),
    payoutByTicker: (tickers: string, from: string, to: string, period: string) =>
      setPrefix(`/api/data/financials/payout?tickers=${tickers}&from=${from}&to=${to}&period=${period}`),
    epsBasic: (tickers: string, from: string, to: string, period: string) =>
      setPrefix(`/api/data/financials/eps-basic?tickers=${tickers}&from=${from}&to=${to}&period=${period}`),
    calcFinancials: (fields: string, tickers: string, from: string, to: string, period: string) =>
      setPrefix(
        `/api/data/financials/calc-financials?fields=${fields}&tickers=${tickers}&from=${from}&to=${to}&period=${period}`,
      ),
    revenuePerShare: (tickers: string, from: string, to: string, period: string) =>
      setPrefix(`/api/data/financials/revenue-per-share?tickers=${tickers}&from=${from}&to=${to}&period=${period}`),
    balanceSheetByTicker: (tickers: string, from: string, to: string, period: string) =>
      setPrefix(`/api/data/financials/balance-sheet?tickers=${tickers}&from=${from}&to=${to}&period=${period}`),
    cashFlowByTicker: (tickers: string, from: string, to: string, period: string) =>
      setPrefix(`/api/data/financials/cash-flow?tickers=${tickers}&from=${from}&to=${to}&period=${period}`),
    currentFinancialsByTicker: (tickers: string) => setPrefix(`/api/data/financials/ttm-financial?tickers=${tickers}`),
    incomeStatementByTicker: (tickers: string, from: string, to: string, period: string) =>
      setPrefix(`/api/data/financials/income-statement?tickers=${tickers}&from=${from}&to=${to}&period=${period}`),
    marginsByTicker: (tickers: string, from: string, to: string, period: string) =>
      setPrefix(`/api/data/financials/margins?tickers=${tickers}&from=${from}&to=${to}&period=${period}`),
    financialReturnsByTicker: (tickers: string, from: string, to: string) =>
      setPrefix(`/api/data/financials/financial-returns?tickers=${tickers}&from=${from}&to=${to}`),
    valuationRatios: (tickers: string, from: string, to: string, ignored: string) =>
      setPrefix(`/api/data/chart/valuation-ratios?tickers=${tickers}&from=${from}&to=${to}`),
    perShareByTicker: (tickers: string, from: string, to: string, period: string) =>
      setPrefix(`/api/data/financials/per-share?tickers=${tickers}&from=${from}&to=${to}&period=${period}`),
    dividendsByMonth: (tickers: string, from: string, to: string) =>
      setPrefix(`/api/data/dividends/by-month?tickers=${tickers}&from=${from}&to=${to}`),
    capitalAllocationByTicker: (tickers: string, from: string, to: string, period: string) =>
      setPrefix(`/api/data/financials/capital-allocation?tickers=${tickers}&from=${from}&to=${to}&period=${period}`),
    odata: (filter: string, select: string, order: string, top: number, skip: number) =>
      setPrefix(
        `/api/data/indicator/odata?$filter=${filter}&$select=${select}&$orderby=${order}&$top=${top}&$skip=${skip}&$count=true`,
      ),
    odatav2: (filter: string, select: string, order: string, top: number, skip: number) =>
      setPrefix(
        `/api/data/indicator/odatav2?$filter=${filter}&$select=${select}&$orderby=${order}&$top=${top}&$skip=${skip}&$count=true`,
      ),
    sheetNames: (ticker: string) => setPrefix(`/api/data/sheet/?ticker=${ticker}`),
    sheetData: (ticker: string, name: string) => setPrefix(`/api/data/sheet/data/${ticker}/${name}`),
    greenStocks: (
      fields: string[],
      orderBy: string,
      order: string,
      skip: number,
      limit: number,
      greenFilter: { isGreenEnergy: boolean; isGreenTransport: boolean; isGreenWater: boolean; isGreenWaste: boolean },
    ) =>
      setPrefix(
        `/api/data/green/stocks?fields=${fields.join(',')}&orderBy=${orderBy}&order=${order}&skip=${skip}&limit=${limit}&isGreenEnergy=${greenFilter.isGreenEnergy ? 1 : 0}&isGreenTransport=${greenFilter.isGreenTransport ? 1 : 0}&isGreenWater=${greenFilter.isGreenWater ? 1 : 0}&isGreenWaste=${greenFilter.isGreenWaste ? 1 : 0}`,
      ),
  },
};
