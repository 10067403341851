import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faStar,
  faArrowRight,
  faArrowUp,
  faChevronRight,
  faUser,
  faKey,
  faTree,
  faSearch,
  faBars,
  faEnvelope,
  faTimes,
  faAddressCard,
  faRedo,
  faBarcode,
  faQuestion,
  faLeaf,
  faExclamationTriangle,
  faCalendar,
  faNewspaper,
  faGripLines,
  faChartLine,
  faEye,
  faRecycle,
  faCarSide,
  faSolarPanel,
  faTint
} from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar, faEnvelope as farEnvelope, faImage as farImage } from '@fortawesome/free-regular-svg-icons';
import { faMicrosoft, faGoogle, faTwitter, faFacebook, faDiscord } from '@fortawesome/free-brands-svg-icons';

export function configureFontAwesome() {
  library.add(
    faStar,
    farStar,
    faArrowRight,
    faArrowUp,
    faChevronRight,
    faUser,
    faKey,
    faTree,
    faSearch,
    faBars,
    faMicrosoft,
    faGoogle,
    farEnvelope,
    faEnvelope,
    faTimes,
    faAddressCard,
    faRedo,
    faBarcode,
    faTwitter,
    faFacebook,
    faDiscord,
    faQuestion,
    farImage,
    faLeaf,
    faExclamationTriangle,
    faCalendar,
    faNewspaper,
    faGripLines,
    faChartLine,
    faEye,
    faRecycle,
    faCarSide,
    faSolarPanel,
    faTint
  );
}
