import { hashMessage, recoverAddress } from 'ethers';
import { disconnect as _disconnect, getAccount, signMessage } from '@wagmi/core';
import { useEffect, useState } from 'react';
import { getRabbyKitModal } from './get-rabby-kit.modal';
import { wrapFetch } from '../api/wrap.fetch';
import { getBackendUrlWithParams } from '../api/get-backend-url';

export async function verifySignature(address, signature, message) {
  // Compute the hash of the message
  const messageHash = hashMessage(message);

  // Recover the address from the signature
  const recoveredAddress = recoverAddress(messageHash, signature);

  console.log(
    'address:',
    address,
    'message:',
    message,
    'message hash:',
    messageHash,
    'signature:',
    signature,
    'recovered address:',
    recoveredAddress,
  );

  // Check if the recovered address matches the provided address
  return recoveredAddress.toLowerCase() === address.toLowerCase();
}

export function useWalletConnect() {
  const [rabbyKitModal, setRabbyKitModal] = useState<any>();
  const [account, setAccount] = useState<any>();
  const [accessToken, setAccessToken] = useState<string | null>();
  const [loggedInViaWallet, setLoggedInViaWallet] = useState<boolean>(false);
  const [walletAuthState, setWalletAuthState] = useState<
    'not-checked' | 'check-in-progress' | 'authenticated' | 'not-authenticated'
  >('not-checked');

  useEffect(() => {
    const rabbyKit = getRabbyKitModal();
    setRabbyKitModal(rabbyKit);

    const storedAccountAddress = localStorage.getItem('accountAddress');
    if (storedAccountAddress) {
      setAccount({ address: storedAccountAddress, isConnected: false });
    } else {
      setAccount(getAccount());
    }
  }, []);

  function refreshAccount() {
    setAccount(getAccount());
  }

  useEffect(() => {
    if (account?.address && !loggedInViaWallet) {
      (async () => {
        try {
          setWalletAuthState('check-in-progress');
          const { address } = await wrapFetch(() =>
            fetch(
              getBackendUrlWithParams('/api/auth/wallet/test-login', {
                address: account.address,
              }),
            ),
          );
          if (address === account.address) {
            setLoggedInViaWallet(true);
            setWalletAuthState('authenticated');
            // Store the account address in localStorage
            localStorage.setItem('accountAddress', account.address);
          } else {
            setWalletAuthState('not-authenticated');
            localStorage.removeItem('accountAddress');
          }
        } catch (e) {
          console.error(e);
          console.log('not logged in');
          setWalletAuthState('not-authenticated');
        }
      })();
    }
  }, [account]);

  async function sendLoginRequest() {
    const account = getAccount();
    console.log(account);

    try {
      const { nonce, message, token } = await wrapFetch(() =>
        fetch(
          getBackendUrlWithParams('/api/auth/wallet/nonce', {
            address: account.address,
          }),
        ),
      );

      const signature = await signMessage({ message: message });

      const verify = await wrapFetch(() =>
        fetch(
          getBackendUrlWithParams('/api/auth/wallet/verify-login', {
            address: account.address,
          }),
          {
            method: 'POST',
            body: JSON.stringify({ token, signature }),
            headers: { 'Content-Type': 'application/json' },
          },
        ),
      );

      console.log('verification successful:', verify);
      if (verify.address !== account.address) {
        throw new Error('Verification failed');
      }

      console.log('Login successful');
      setLoggedInViaWallet(true);
      setWalletAuthState('authenticated');
      localStorage.setItem('accountAddress', account.address);
    } catch (e) {
      console.error(e);
      console.log('Login failed');
      setWalletAuthState('not-authenticated');
    }
  }

  async function openModal() {
    console.log('open modal');
    await _disconnect();
    refreshAccount();
    rabbyKitModal.rabbyKit.open({
      onConnect: async () => {
        await sendLoginRequest();
      },
    });
  }

  function closeModal() {
    rabbyKitModal?.rabbyKit?.close();
  }

  async function disconnect() {
    await _disconnect();
    //Clear Login
    await wrapFetch(() => fetch(getBackendUrlWithParams('/api/auth/wallet/clear-login'), { method: 'POST' }));
    setAccount(getAccount());
    setLoggedInViaWallet(false);
    // Remove the account address from localStorage
    localStorage.removeItem('accountAddress');
  }

  return { account, openModal, closeModal, disconnect, loggedInViaWallet, walletAuthState };
}
